import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'

/**
 * A listing of all my more prominent projects
 * 
 * @param  {object} data - Page data for all projects
 * @return {object}      - React markup
 */
const Projects = ({
  data: {
    allMdx: {
      nodes: pages
    }
  }
}) => (
  <Layout>
    <SEO title="Projects" />
    { 
      pages
        .sort((a, b) => b.parent.birthtimeMs - a.parent.birthtimeMs)
        .map((page, i) => (
          <Section
            { ...page.frontmatter }
            key={ page.parent.name }
            align={ i % 2 ? 'right' : 'left' }
            path={ `/${page.parent.name}` }
            blurb={ page.excerpt }
          />
        ))
    }
  </Layout>
)

export const query = graphql`
  query {
    allMdx(filter: {frontmatter: {type: {eq: "project"}}}) {
      nodes {
        frontmatter {
          image
          tags
          title
        }
        excerpt(pruneLength: 250)
        parent {
          ... on File {
            name
            birthtimeMs
          }
        }
      }
    }
  }
`

export default Projects